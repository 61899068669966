<template>
  <v-app>
    <v-main>
      <centered-layout>
        <div class="text-center">
          <h1 class="title mb-4">{{ $t('views.auth.title') }}</h1>
          <v-btn size="medium" depressed @click="handleAuthClick">
            {{ $t('views.auth.login') }}
          </v-btn>
        </div>
      </centered-layout>
    </v-main>
  </v-app>
</template>

<script>
import { ProceedMixin } from '@lib/vue-playrix-auth/mixins';

import CenteredLayout from './layouts/CenteredLayout.vue';

export default {
  metaInfo() {
    return {
      title: this.$t('views.auth.title'),
    };
  },
  methods: {
    handleAuthClick() {
      this.proceedAuth();
    },
  },
  components: { CenteredLayout },
  mixins: [ProceedMixin],
};
</script>
